.title_hd strong {font-weight: 800;}
.csswithtag h4 {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 2px;
}
.expert_team h4 {
    font-size: 30px;
}
.expert_team h4 strong {
    font-weight: 700;
}

.before-color::before {
    background: linear-gradient(90.52deg, #01296C -6.86%, #051F36 106.94%);
    opacity: .9;
    background: linear-gradient(90.3deg, rgba(3, 13, 30, 0.92) -3.84%, rgba(3, 13, 30, 0.92) 49.99%, rgba(3, 13, 30, 0.92) 102.9%);
}
.checktag ul li {
    background-image: url("https://backend.vlinkinfo.com/uploads/icon_park_solid_check_one_f9e5e8ebc0.png");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left 8px;
    padding-left: 30px;
    font-weight: 600;
    margin-bottom: 20px;
}
.checktag p{
    margin-bottom: 20px;
}
.scaleeffect {
    transform: scale(1.2);
    transform-origin: top left;
    transition: .5s ease;
}
.scaleeffect:hover {
    transform: scale(1);}
@media screen and (max-width: 1024px) {
    .csswithtag h4 {
        font-size: 18px;
    }
    .expert_team h4 {
        font-size: 22px;
    }
}